
import React from "react";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
import _ from "lodash";

import {
  Header,
  Package as PackageURL,
  ServerAddress,
} from "../../Enum/Urls";

import {
  editMessage,
  SwitchAccountWindow,
  toggleAccountWindow,
} from "../../Actions/VisualDataAction";
import {
  saveParameters,
  postReserve,
} from "../../Actions/HotelAction";
import Loading from "../../components/Common/Loading";
import DatePickerDrop from "../../components/Common/datePickerDrop";
import { Steps} from "antd";
import Form from "../../components/Common/Form";
import PackageSummary from "../../components/Package/PackageSummary";
import { CountryCode, CountryPostalCode } from "../../Enum/Models";
import Validation from "../../components/Common/Validation";
import formIconOrange from "../../image/form-icon.svg";
import formIconPink from "../../image/form-icon-pink.svg";
import "react-phone-number-input/style.css";
import LayoutConfig from "../../Config/layoutConfig";

class GetPassengerPackage extends Form {
  state ={
    ...this.state,
    packageDetails:undefined,
    submitLoading:false,
  }

  
  getPackageInformation = async () => {
    let parameters = queryString.parse(this.props.location.search);

    try {
      const response = await axios.get(`${ServerAddress.Type}${ServerAddress.Package}${PackageURL.GetByPrereserveKey}?PreReserveKey=${parameters.preReserveKey}`,{headers: {...Header,},});
      if (response){
        this.setState({
          packageDetails:response.data.result
        })
        }
    } catch (error) {
      debugger;
        // if (error.response) {
        // } else if (error.request) {
        // } else {
        // }
    }

  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getPackageInformation();
    super.componentDidMount(this.FormDOM);
  }
  fixNumbers = str => {
    const persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g];
    const arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
    if(typeof str === 'string'){
      for(let i=0; i<10; i++){
        str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
      }
    }
    return str;
  };
  
    RoomRender = [];
    
    FormDOM = [];

    handleSubmit(event) {
      event.preventDefault();
      super.validateForm(event, () => {
        //this.props.editMessage(false);
        let dataModelInput = this.state.DataInput;
        // dataModelInput.passengers.forEach((item)=>{
        //     if(item.gender==="true"){
        //         item.firstName="MR "+item.firstName;
        //     }else{
        //        item.firstName="MRS "+item.firstName;
        //     }
        // });

        this.setState({submitLoading:true});

        let parameters = queryString.parse(this.props.location.search);
        let code = dataModelInput.reserver.countryCallingCode;
        if (code.substring(0, 2) === "00"){
          code = "+" + code.substring(2)
        }
        let hotelParameters = {};
        if (dataModelInput.specialRequest){
          hotelParameters={specialRequest:dataModelInput.specialRequest}
        }
        let passengers  = [...dataModelInput.passengers];
        let updatedPassengers = [];
        for (let i = 0;i<passengers.length ; i++){
          let updatedPassenger = {...passengers[i]};
          updatedPassenger.roomNumber = +updatedPassenger.roomNumber;
          updatedPassenger.passportNumber = this.fixNumbers(updatedPassenger.passportNumber);
          updatedPassenger.gender = (updatedPassenger.gender==="true")?true:false;
          updatedPassenger.hasWheelChair = updatedPassenger.hasWheelChair?true:false;
          updatedPassenger.mealType = (updatedPassenger.mealType || updatedPassenger.mealType==="0")?updatedPassenger.mealType:null;
          updatedPassengers.push(updatedPassenger);
        }
        let params = {
          "preReserveKey": parameters.preReserveKey,
          "reserver": {
            "gender": (dataModelInput.reserver.gender === "true")?true:false,
            "firstName": dataModelInput.reserver.firstName,
            "lastName": dataModelInput.reserver.lastName,
            //"phoneNumber": this.fixNumbers(dataModelInput.reserver.phoneNumber),
            "phoneNumber":code + '' + this.fixNumbers(dataModelInput.reserver.phoneNumber),
            "email": dataModelInput.reserver.email,
            "username": dataModelInput.reserver.email,
            "countryCallingCode": dataModelInput.reserver.countryCallingCode
            //"countryCallingCode": code
          },
          "reserveFlight": {
            "isBookNowRequested": false,
            "flightKey": this.state.packageDetails.preReservedFlight.pricedItinerary.flightKey
          },
          "reserveHotel": hotelParameters,
          "passengers":updatedPassengers
        };
        axios.post(`${ServerAddress.Type}${ServerAddress.Package}${PackageURL.reserve}`,params,{headers: {...Header,},})
        .then(({ data }) => {
          if (data.error) {
          }else{
            this.props.history.push(
              `/payment/reserve?reserveId=${data.result.id}`
            );
          }
        })
        .catch((error) => {
          debugger;;
        });
      });
    }

    // const genderSelectfixSelect = (e,defaultVal) => getFieldDecorator(e, {
    //   initialValue: defaultVal ? defaultVal :true,
    // })(
    //   <select>
    //     <option value={true}>{this.props.Dictionary.male}</option>
    //     <option value={false}>{this.props.Dictionary.female}</option>
    //   </select>,
    // );

    // const countryCodeSelect = getFieldDecorator("reserver.postalcode", {
    //   initialValue: LAN === "IR" ? "0098" : LAN === "AR"? "00971" : "0047",
    // })(
    //   <select className="form-select" dir="ltr" >
    //     {CountryPostalCode.sort((a,b)=>{
    //       let x = a.code.toLowerCase();
    //       let y = b.code.toLowerCase();
    //       if (x < y) {return -1;}
    //       if (x > y) {return 1;}
    //       return 0;
    //     }).map((item) => (
    //       <option
    //         key={item.code}
    //         value={item.value}
    //       >
    //         {item.code}
    //       </option>
    //     ))}
    //   </select>,
    // );
    onChangeValidation(event, type, maxLength) {
      if (maxLength) {
        if (event.target.value.length > maxLength) {
          const prevVal = event.target.value.substring(0, maxLength);
          event.target.value = prevVal;
        }
      }
      let regex;
      switch (type) {
        case 'number':
          regex = /^[0-9۰-۹٠-٩]*$/;
          break;
        case 'enAlphabet':
          regex = /^[a-zA-Z ]*$/;
          break;
        case 'faAlphabet':
          regex = /^[\u0600-\u06FF ]*$/;
          break;
        case 'email':
          regex = /[^\u0600-\u06FF\s]/;
          break;
        default:
          regex = /^[a-zA-Z ]*$/;
      }
  
      if (!regex.test(event.target.value)) {
        const prevVal = event.target.value.substring(0, event.target.value.length - 1);
        if (regex.test(prevVal)) {
          event.target.value = prevVal;
        } else {
          event.target.value = '';
        }
      }
    }  

    ifEnglish = string => {
      const regex = /^[a-zA-Z ]*$/;
      if (!regex.test(string)){
        return "";
      }
      return string;
    }
   
render(){
  const { Dictionary, LAN } = this.props;
  const {userData} = this.props;
  const { Step } = Steps;

  let userInfoStatus = false;
  if (userData && userData.id){
     userInfoStatus = true;
  }

  const updatedUserData = userInfoStatus ?{
    ...userData,
    firstName:this.ifEnglish(userData.firstName),
    lastName:this.ifEnglish(userData.lastName)
  }:undefined;

  let passengerCounter = -1;
  let passengerCounterForSpecialFood = -1;
  const specialDietsList = [
    {value:0,title:"No special diet"},
    {value:1,title:"Vegetarian"},
    {value:2,title:"Lactose free"},
    {value:3,title:"Gluten free"},
    {value:4,title:"Kosher meal"},
    {value:5,title:"Muslim meal"}
  ];
  let userPhoneNumber = undefined;
  const formIcon = LayoutConfig.themeClassName === 'naminTravel-theme' ? formIconPink : formIconOrange;
    return !this.state.packageDetails ? (
      <Loading style2={LayoutConfig.themeClassName === "style-2"} fullPage description={Dictionary.GettingPassengerInformation+" ..."}/>
      // <div className="loading-outer-container">
      //   <div>
      //     <img
      //       src={LayoutConfig.logo ? LayoutConfig.logo : siteLogo}
      //       className="loading-logo"
      //       alt={LayoutConfig.companyName ? LayoutConfig.companyName : "itours"}
      //     />
      //     <div className="loading-inner-container">
      //       <div className="item-1"></div>
      //       <div className="item-2"></div>
      //       <div className="item-3"></div>
      //       <div className="item-4"></div>
      //       <div className="item-5"></div>
      //     </div>
      //     <div className="text-center">
      //       {Dictionary.GettingPassengerInformation}
      //       ...
      //     </div>
      //   </div>
      // </div>
    ) : (
      <div className="get-passenger-form-holder package">
        {this.state.submitLoading && <Loading style2={LayoutConfig.themeClassName === "style-2"} fullPage />}
        <div className="page-padding getpassenger-page">
          <div className="page-container">
            <Steps current={1}>
              <Step title={Dictionary.chooseFlightsOrHotels} />
              <Step title={Dictionary.enterThePassengersInformation} />
              <Step title={Dictionary.paymentTypeOptions} />
            </Steps>  

            {/* <form
              className="package-passengers-form"
                name="package_passengers_form"
                onSubmit={this.handleSubmit}
              > */}
              <form
                className="package-passengers-form"
                id={"reserveForm"}
                onSubmit={this.handleSubmit.bind(this)}
                ref={(node) => (this.FormDOM = node)}
                noValidate
              >
              <div className="getPassengers">
                <div className="sides-outer-holder">
                  <div className="sides-holder">

                    <div className="large-side">
                      <div className="side-item full-width">
                        <div className="side-item-inner">

                          <div className="margin-bottom bg-white responsive-bg-transparent box-border card-padding blue-border-right">
                            <h5 className="title">
                              {Dictionary.enterContactDetails}
                            </h5>
                            <div className="icon-hint-holder">
                              <img src={formIcon} className="icon" alt="" />
                              <div className="hint-inner">
                                {Dictionary.BookingInformationsWillbeSentToThisNumberOrEmail}
                              </div>
                            </div>                              
                            <div className="float-row">
                              <div className="col-small-12 col-medium-6">
                                <div className="form-group required">
                                  <label className="form-label">
                                    {Dictionary.firstNameEn}
                                  </label>
                                  <div className="select-input-pair-holder">
                                    <select
                                      className="form-select"
                                      name={"reserver.gender"}
                                      defaultValue={
                                        userInfoStatus ? userData.gender:true
                                      }
                                    >
                                      <option value={true}>
                                        {Dictionary.male}
                                      </option>
                                      <option value={false}>
                                        {Dictionary.female}
                                      </option>
                                    </select>
                                    <input
                                      onChange={e => {this.onChangeValidation(e,"enAlphabet")}}
                                      data-validator-type="alphabet"
                                      className="form-input"
                                      data-validator={true}
                                      placeholder={Dictionary.firstName}
                                      name="reserver.firstName"
                                      type="text"
                                      defaultValue={updatedUserData?.firstName || ""}
                                    />
                                  </div>
                                  <Validation
                                    model={this.state}
                                    feild="ValidatorList"
                                    name="reserver.firstName"
                                  />
                                </div>
                              </div>

                              <div className="col-small-12 col-medium-6">
                                <div className="form-group required">
                                  <label className="form-label">
                                    {Dictionary.lastNameEn}
                                  </label>
                                  <input
                                    onChange={e => {this.onChangeValidation(e,"enAlphabet")}}
                                    data-validator-type="alphabet"
                                    className="form-input"
                                    data-validator={true}
                                    placeholder={Dictionary.lastName}
                                    name="reserver.lastName"
                                    type="text"
                                    defaultValue={updatedUserData?.lastName || ""}
                                  />
                                  <Validation
                                    model={this.state}
                                    feild="ValidatorList"
                                    name="reserver.lastName"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="float-row">
                              <div className="col-small-12 col-medium-6">
                                <div className="form-group required">
                                  {/* {LAN === "IR" ? (
                                    <label htmlFor="" className="form-label">
                                      {Dictionary.email}({Dictionary.optional})
                                    </label>
                                  ) : ( */}
                                    <label htmlFor="" className="form-label">
                                      {Dictionary.email}
                                    </label>
                                  {/* )} */}
                                  <input
                                    //data-validator={LAN !== "IR"}
                                    onChange={e => {this.onChangeValidation(e,"email")}}
                                    data-validator={true}
                                    className="form-input placeholder-right"
                                    placeholder={Dictionary.enterYourEmail}
                                    name="reserver.email"
                                    type="email"
                                    defaultValue={userInfoStatus ? userData.emailAddress:""}
                                  />
                                  <Validation
                                    model={this.state}
                                    feild="ValidatorList"
                                    name="reserver.email"
                                  />
                                </div>
                              </div>

                              <div className="col-small-12 col-medium-6">
                                <div className="form-group required" >
                                  {/* {LAN !== "IR" ? (
                                    <label htmlFor="" className="form-label">
                                      {Dictionary.phoneNumber}({Dictionary.optional})
                                    </label>
                                  ) : ( */}
                                    <label htmlFor="" className="form-label">
                                      {Dictionary.phoneNumber}
                                    </label>
                                  {/* )} */}
                                  <div
                                    className="select-input-pair-holder "
                                    dir="ltr"
                                  >
                                    <select
                                      className="form-select"
                                      name={"reserver.countryCallingCode"}
                                      dir="ltr"
                                    >
                                      {CountryPostalCode.sort((a,b)=>{
                                        let x = a.code.toLowerCase();
                                        let y = b.code.toLowerCase();
                                        if (x < y) {return -1;}
                                        if (x > y) {return 1;}
                                        return 0;
                                      }).map((item) => {
                                        let seleceted = false;
                                        if (userData && userData.phoneNumber){
                                          let code = item.value;
                                          if (code.substring(0, 2) === "00"){
                                            code = "+" + code.substring(2);
                                          }
                                          if (userData.phoneNumber.includes(code)){
                                            seleceted=true;
                                            let phone_Number = userData.phoneNumber.replace(code,'');
                                            userPhoneNumber=phone_Number
                                          }
                                        }else{
                                          if((item.value === "0098" && LAN === "IR") || (item.value === "0090" && LAN === "TR") || (item.value === "00971" && LAN === "AR") || (item.code === "Norway (+47)" && LAN !== "AR" && LAN !== "IR" ) ){
                                            seleceted=true;
                                          }
                                        }
                                        return(<option
                                          key={item.code}
                                          value={item.value}
                                          selected={seleceted }
                                        >
                                          {item.code}
                                        </option>)
                                      })}
                                    </select>

                                    <input
                                      onChange={e => {this.onChangeValidation(e,"number")}}
                                      className="form-input placeholder-right"
                                      defaultValue={userPhoneNumber ? userPhoneNumber : undefined}
                                      data-validator={true}
                                      placeholder="9XXXXXXXXX"
                                      name="reserver.phoneNumber"
                                      type="tel"
                                    />
                                  </div>
                                  <Validation
                                    model={this.state}
                                    feild="ValidatorList"
                                    name="reserver.phoneNumber"
                                  />
                                </div>
                              </div>
                            </div>
                            {userInfoStatus ? (
                                <>
                                  <input
                                    type="hidden"
                                    name="reserver.userId"
                                    value={userData.id}
                                  />
                                  
                                  <input
                                    type="hidden"
                                    name="reserver.username"
                                    defaultValue={this.state.username}
                                  />
                                </>
                              ) : (
                                <>
                                  <input
                                    type="hidden"
                                    name="reserver.userId"
                                    value={null}
                                  />
                                  <input
                                    type="hidden"
                                    name="reserver.username"
                                    defaultValue={null}
                                  />
                                </>
                              )}
                          </div>
                          
        
                          <div className="box-border bg-white responsive-bg-transparent get-passenger-form-holder margin-bottom blue-border-right">
                            <div className="card-padding">
                                <h5 className="title">
                                  {Dictionary.enterTravellerDetails}
                                </h5>
                                <div className="icon-hint-holder no-margin-bottom">
                                  <img src={formIcon} className="icon" alt="" />
                                  <div className="hint-inner">
                                    <span>
                                      {
                                        Dictionary.EnterYourDetailsAsTheyAppearOnYourPassport
                                      }
                                    </span>
                                    <span> {Dictionary.useEnglishOnly}.</span>
                                  </div>
                                </div>
                              </div>
                            <div
                              className={this.RoomRender.length > 1 ? "multiple-room" : " "}>
                              {this.state.packageDetails.preReservedHotel.accommodation.rooms.map((roomItem, roomIndex) => {
                                return (
                                  <div
                                    className="RoomItem"
                                    key={roomIndex}
                                  >
                                    <div className="card-hr-padding">
                                      <h5 className="normal-title">
                                        <span className="inline-block margin-end">
                                          {Dictionary.room} {roomIndex + 1}
                                        </span>
                                        <span
                                          className="inline-block en-font"
                                          dir="ltr"
                                        >
                                          ({this.state.packageDetails.preReservedHotel.accommodation.rooms[roomIndex].name})
                                        </span>
                                      </h5>
                                    </div>
                                    <div>
                                      {_.times(roomItem.adults, "adt").map((item, indexIN) => {
                                        passengerCounter++;
                                        return (
                                          <div key={indexIN} className="margin-top-half package-passenger-item">
                                            <div className="card-hr-padding">
                                              <div><span className="itours-icon icon-large adult margin-end-half" /> <span className="semi-bold">{Dictionary.passenger} {passengerCounter+1}</span> ({Dictionary.adult}) </div>

                                              <input type="hidden" value="ADT" name={`passengers[${passengerCounter}].code`} />
                                              <input type="hidden" value={roomIndex+1} name={`passengers[${passengerCounter}].roomNumber`} />
                                              
                                              <div className="float-row">
                                                <div className="col-xsmall-12 col-small-6">
                                                  <div className="form-group required">
                                                    <label className="form-label">
                                                      {Dictionary.firstNameEn}
                                                    </label>
                                                    <div className="select-input-pair-holder">
                                                      <select
                                                        className="form-select"
                                                        name={`passengers[${passengerCounter}].gender`}
                                                      >
                                                        <option value="true">
                                                          {Dictionary.male}
                                                        </option>
                                                        <option value="false">
                                                          {Dictionary.female}
                                                        </option>
                                                      </select>
                                                      <input
                                                        onChange={e => {this.onChangeValidation(e,"enAlphabet")}}
                                                        data-validator-type="alphabet"
                                                        data-validator={true}
                                                        type="text"
                                                        className="form-input"
                                                        name={`passengers[${passengerCounter}].firstName`}
                                                      />
                                                    </div>
                                                    <Validation
                                                      model={this.state}
                                                      feild="ValidatorList"
                                                      name={`passengers[${passengerCounter}].firstName`}
                                                    />
                                                  </div>                                               
                                              </div>
                                                <div className="col-xsmall-12 col-small-6">
                                                  <div className="form-group required">
                                                      <label className="form-label">
                                                        {Dictionary.lastNameEn}
                                                      </label>
                                                      <input
                                                        onChange={e => {this.onChangeValidation(e,"enAlphabet")}}
                                                        data-validator-type="alphabet"
                                                        className="form-input"
                                                        data-validator={true}
                                                        placeholder={Dictionary.lastName}
                                                        name={`passengers[${passengerCounter}].lastName`}
                                                        type="text"
                                                      />
                                                      <Validation
                                                        model={this.state}
                                                        feild="ValidatorList"
                                                        name={`passengers[${passengerCounter}].lastName`}
                                                      />
                                                    </div> 
                                                </div>
                                              </div>
                                              <div className="float-row">
                                                <div className="col-small-12 col-medium-6">
                                                  <div className="form-group required">
                                                    <label className="form-label" >
                                                      {Dictionary.birthdayMiladi}
                                                    </label>
                                                    <DatePickerDrop
                                                      emptyDefault
                                                      //defaultValue={moment().subtract(100, "y")}
                                                      format={"YYYY-MM-DD"}
                                                      name={`passengers[${passengerCounter}].birthDate`}
                                                      // min={this.state.rangeAge["ADT"].min}
                                                      // max={this.state.rangeAge["ADT"].max}
                                                      min={moment().subtract(100, "y").format("YYYY-MM-DD")}
                                                      max={moment().subtract(12, "y").format("YYYY-MM-DD")}
                                                      dataValidatorType="datePickerDrop"
                                                      dataValidator={true}
                                                    />
                                                    <Validation
                                                      model={this.state}
                                                      feild="ValidatorList"
                                                      name={`passengers[${passengerCounter}].birthDate`}
                                                    />
                                                  </div>
                                                </div>                                                
                                                <div className="col-xsmall-12 col-small-6">
                                                  <div className="form-group required">
                                                    <label
                                                      htmlFor=""
                                                      className="form-label"
                                                    >
                                                      {Dictionary.nationality}
                                                    </label>
                                                    <select
                                                      className="form-select"
                                                      //onChange={(e)=>{this.setPassengerNationality(e.target.value,index)}}
                                                      defaultValue={LAN === "IR" ? "IR": LAN ==="TR"?"TR" : "NO"}
                                                      name={`passengers[${passengerCounter}].nationalityCode`}
                                                      dir="ltr"
                                                      data-validator={true}
                                                    > 
                                                      <option disabled selected value="false"> -- </option>
                                                      {CountryCode.map((item) => (
                                                        <option
                                                          value={item.value}
                                                          key={item.value}
                                                        >
                                                          {item.code}
                                                        </option>
                                                      ))}
                                                    </select>
                                                    <Validation
                                                      model={this.state}
                                                      feild="ValidatorList"
                                                      name={`passengers[${passengerCounter}].nationalityCode`}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="float-row">
                                                <div className="col-xsmall-12 col-small-6">
                                                  <div className="form-group required">
                                                    <label
                                                      htmlFor=""
                                                      className="form-label"
                                                    >
                                                      {Dictionary.passportNumber}
                                                    </label>
                                                    <input
                                                      data-validator-type="passportNumber"
                                                      type="text"
                                                      className="form-input"
                                                      data-validator={true}
                                                      name={`passengers[${passengerCounter}].passportNumber`}
                                                    />
                                                    <Validation
                                                      model={this.state}
                                                      feild="ValidatorList"
                                                      name={`passengers[${passengerCounter}].passportNumber`}
                                                    />
                                                  </div>                                                
                                                </div>

                                                <div className="col-xsmall-12 col-small-6">
                                                  <div className="form-group required">
                                                    <label className="form-label">
                                                      {Dictionary.passportExpDate}
                                                    </label>
                                                    <DatePickerDrop
                                                      emptyDefault
                                                      //defaultValue={moment().add(1, "d")}
                                                      format={"YYYY-MM-DD"}
                                                      name={`passengers[${passengerCounter}].passportExpireDate`}
                                                      // min={this.state.expDate}
                                                      // max={moment(
                                                      //   this.state.expDate
                                                      // ).add(10, "years")}
                                                      min={moment().add(1, "d").format("YYYY-MM-DD")}
                                                      max={moment().add(10, "y").format("YYYY-MM-DD")}
                                                      dataValidatorType="datePickerDrop"
                                                      dataValidator={true}
                                                    />
                                                    <Validation
                                                      model={this.state}
                                                      feild="ValidatorList"
                                                      name={`passengers[${passengerCounter}].passportExpireDate`}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {(process.env.REACT_APP_THEME ==='NAMINTRAVELCOM') &&  <div className="wheelchair-block text-center">
                                              <h5 className="wheelchair-block-title">{Dictionary.thisServiceIsFree}</h5>
                                              <div className="checkbox-holder">
                                                <input
                                                  name={`passengers[${passengerCounter}].hasWheelChair`}
                                                  className="custom-checkbox"
                                                  type="checkbox"
                                                />
                                                <label className="checkbox-label font-14">
                                                  {Dictionary.clickHereIfYouNeedAWheelchair}
                                                </label>
                                              </div>
                                            </div>}                                            
                                          </div>
                                        );
                                      })}
                                    </div>

                                    <div>
                                      {roomItem.ages.map((item, indexIN) => {
                                        passengerCounter++;
                                        return (<div key={indexIN} className="margin-top-half package-passenger-item">
                                          <div className="card-hr-padding">
                                            <div>
                                              <span className={`itours-icon icon-large ${item>1?"child":"infant"} margin-end-half`} />
                                              <span className="semi-bold">{Dictionary.passenger} {passengerCounter+1}</span>
                                              ({item>1?Dictionary.child:Dictionary.infant}) 
                                            </div>
                                            <input type="hidden" value="CHD" name={`passengers[${passengerCounter}].code`} />
                                            <input type="hidden" value={roomIndex+1} name={`passengers[${passengerCounter}].roomNumber`} />
                                            
                                            <div className="float-row">
                                              <div className="col-xsmall-12 col-small-6">
                                                <div className="form-group required">
                                                  <label className="form-label">
                                                    {Dictionary.firstNameEn}
                                                  </label>
                                                  <div className="select-input-pair-holder">
                                                    <select
                                                      className="form-select"
                                                      name={`passengers[${passengerCounter}].gender`}
                                                    >
                                                      <option value="true">
                                                        {Dictionary.male}
                                                      </option>
                                                      <option value="false">
                                                        {Dictionary.female}
                                                      </option>
                                                    </select>
                                                    <input
                                                      onChange={e => {this.onChangeValidation(e,"enAlphabet")}}
                                                      data-validator-type="alphabet"
                                                      data-validator={true}
                                                      type="text"
                                                      className="form-input"
                                                      name={`passengers[${passengerCounter}].firstName`}
                                                    />
                                                  </div>
                                                  <Validation
                                                    model={this.state}
                                                    feild="ValidatorList"
                                                    name={`passengers[${passengerCounter}].firstName`}
                                                  />
                                                </div>                                               
                                            </div>
                                              <div className="col-xsmall-12 col-small-6">
                                                <div className="form-group required">
                                                    <label className="form-label">
                                                      {Dictionary.lastNameEn}
                                                    </label>
                                                    <input
                                                      onChange={e => {this.onChangeValidation(e,"enAlphabet")}}
                                                      data-validator-type="alphabet"
                                                      className="form-input"
                                                      data-validator={true}
                                                      placeholder={Dictionary.lastName}
                                                      name={`passengers[${passengerCounter}].lastName`}
                                                      type="text"
                                                    />
                                                    <Validation
                                                      model={this.state}
                                                      feild="ValidatorList"
                                                      name={`passengers[${passengerCounter}].lastName`}
                                                    />
                                                  </div> 
                                              </div>
                                            </div>
                                            <div className="float-row">
                                              <div className="col-small-12 col-medium-6">
                                                <div className="form-group required">
                                                  <label className="form-label" >
                                                    {Dictionary.birthdayMiladi}
                                                  </label>
                                                  <DatePickerDrop
                                                    emptyDefault
                                                    //defaultValue={moment()}
                                                    format={"YYYY-MM-DD"}
                                                    name={`passengers[${passengerCounter}].birthDate`}
                                                    // min={this.state.rangeAge["ADT"].min}
                                                    // max={this.state.rangeAge["ADT"].max}
                                                    min={moment().subtract(12, "y").format("YYYY-MM-DD")}
                                                    max={moment().format("YYYY-MM-DD").format("YYYY-MM-DD")}
                                                    dataValidatorType="datePickerDrop"
                                                    dataValidator={true}
                                                  />
                                                  <Validation
                                                    model={this.state}
                                                    feild="ValidatorList"
                                                    name={`passengers[${passengerCounter}].birthDate`}
                                                  />
                                                </div>
                                              </div>                                                
                                              <div className="col-xsmall-12 col-small-6">
                                                <div className="form-group required">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label"
                                                  >
                                                    {Dictionary.nationality}
                                                  </label>
                                                  <select
                                                    className="form-select"
                                                    //onChange={(e)=>{this.setPassengerNationality(e.target.value,index)}}
                                                    defaultValue={LAN === "IR" ? "IR": LAN ==="TR"?"TR" : "NO"}
                                                    name={`passengers[${passengerCounter}].nationalityCode`}
                                                    dir="ltr"
                                                    data-validator={true}
                                                    > 
                                                      <option disabled selected value="false"> -- </option>
                                                      {CountryCode.map((item) => (
                                                        <option
                                                          value={item.value}
                                                          key={item.value}
                                                        >
                                                          {item.code}
                                                        </option>
                                                      ))}
                                                    </select>
                                                    <Validation
                                                      model={this.state}
                                                      feild="ValidatorList"
                                                      name={`passengers[${passengerCounter}].nationalityCode`}
                                                    />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="float-row">
                                              <div className="col-xsmall-12 col-small-6">
                                                <div className="form-group required">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label"
                                                  >
                                                    {Dictionary.passportNumber}
                                                  </label>
                                                  <input
                                                    data-validator-type="passportNumber"
                                                    type="text"
                                                    className="form-input"
                                                    data-validator={true}
                                                    name={`passengers[${passengerCounter}].passportNumber`}
                                                  />
                                                  <Validation
                                                    model={this.state}
                                                    feild="ValidatorList"
                                                    name={`passengers[${passengerCounter}].passportNumber`}
                                                  />
                                                </div>                                                
                                              </div>
                                              <div className="col-xsmall-12 col-small-6">
                                                <div className="form-group required">
                                                  <label className="form-label">
                                                    {Dictionary.passportExpDate}
                                                  </label>
                                                  <DatePickerDrop
                                                    emptyDefault
                                                    //defaultValue={moment().add(1, "d")}
                                                    format={"YYYY-MM-DD"}
                                                    name={`passengers[${passengerCounter}].passportExpireDate`}
                                                    // min={this.state.expDate}
                                                    // max={moment(
                                                    //   this.state.expDate
                                                    // ).add(10, "years")}
                                                    min={moment().add(1, "d").format("YYYY-MM-DD")}
                                                    max={moment().add(10, "y").format("YYYY-MM-DD")}
                                                    dataValidatorType="datePickerDrop"
                                                    dataValidator={true}
                                                  />
                                                  <Validation
                                                    model={this.state}
                                                    feild="ValidatorList"
                                                    name={`passengers[${passengerCounter}].passportExpireDate`}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {(process.env.REACT_APP_THEME ==='NAMINTRAVELCOM') && (item>1) && <div className="wheelchair-block text-center">
                                              <h5 className="wheelchair-block-title">{Dictionary.thisServiceIsFree}</h5>
                                              <div className="checkbox-holder">
                                                <input
                                                  name={`passengers[${passengerCounter}].hasWheelChair`}
                                                  className="custom-checkbox"
                                                  type="checkbox"
                                                />
                                                <label className="checkbox-label font-14">
                                                  {Dictionary.clickHereIfYouNeedAWheelchair}
                                                </label>
                                              </div>
                                            </div>}                                           
                                        </div>
                                        );
                                      })}
                                    </div>                                    
            
                                  </div>
                                );
                              })}

                              <div className="border-top no-margin-top padding-h-20 padding-bottom">
                                <div className="checkbox-holder has-link">
                                  <input
                                    className="custom-checkbox"
                                    type="checkbox"
                                    data-validator={true}
                                    name={`acceptRuls`}
                                  />
                                  <label className="checkbox-label font-14">
                                    {LAN === "IR" ? (
                                      <>
                                        <a href={"/terms"} className="content-link">
                                          قوانین و مقررات آیتورز{" "}
                                        </a>
                                        را خوانده ام و با آن موافق هستم.
                                      </>
                                    ) : (
                                      <>
                                        {Dictionary.iHaveReadAndAgreeToThe}
                                        <Link to={"/terms"} className="content-link" target="_blank">
                                          {Dictionary.termsAndConditions}
                                        </Link>
                                      </>
                                    )}
                                  </label>
                                  <Validation
                                    model={this.state}
                                    feild="ValidatorList"
                                    name={`acceptRuls`}
                                  />
                                </div>
                              </div>


                            </div>
                          </div>
                          {process.env.REACT_APP_THEME ==='NAMINTRAVELCOM' &&
                            <div className="box-border bg-white responsive-bg-transparent get-passenger-form-holder padding-20">
                              <h5 className="title">
                                {Dictionary.specialFood}
                              </h5>
                              <p><span className="itours-icon icon-large fork-knife-circle margin-end-half" /> {Dictionary.specialDietP}.</p>

                              <div className="float-row">
                                {this.state.packageDetails.preReservedHotel.accommodation.rooms.map((roomItem, roomIndex) => {
                                    return (<>
                                      {_.times(roomItem.adults, "adt").map((item, indexIN) => {
                                        passengerCounterForSpecialFood++;
                                        return(<div key={indexIN} className="col-xsmall-12 col-small-6 col-large-4 special-diet-passenger-item-col">
                                          <div className="diet-passenger-item-inner">
                                            <h5 className="passenger-title">
                                              {Dictionary.passenger + " " + (+passengerCounterForSpecialFood+1)} <small className="no-wrap gray">
                                              ({Dictionary.adult}{(this.state.packageDetails.preReservedHotel.accommodation.rooms.length>1) && <> - {Dictionary.room + " " + (+roomIndex+1)}</>}
                                              )</small>
                                            </h5>
                                            {specialDietsList.map((dietItem,dietIndex)=><label key={dietItem.title} className='radio-btn-label type-2'>
                                              <input type="radio" 
                                                name={`passengers[${passengerCounterForSpecialFood}].mealType`}
                                                value={dietItem.value}
                                              />
                                              <span className="radio-btn-label-text">{dietItem.title}</span>
                                            </label>)}
                                          </div>
                                          {/* <div className="checkbox-holder">
                                            <input
                                              className="custom-checkbox"
                                              type="checkbox"
                                              name={`allExtraBaggageChecked`}
                                              checked ={passengerItem.services.extraBaggage}
                                              onChange={(e)=>this.changeExtraBaggage(e,passengerIndex)}
                                            />
                                            <label className="checkbox-label font-14">


                                              
                                              <span dir="ltr" className="margin-start-large">
                                                +<Price currency={this.props.currencyCode}>
                                                  {flightSelected.pricedItinerary.airItineraryPricingInfo.ptC_FareBreakdown.filter(typeItem => typeItem.passengerTypeQuantity.code === passengerItem.type)[0].passengerFare.extraBaggage}
                                                </Price>
                                              </span>
                                              
                                            </label>
                                          </div> */}
                                        </div>)
                                      })}
                                      {roomItem.ages.map((item, indexIN) => {
                                        passengerCounterForSpecialFood++;
                                        return(<div key={indexIN} className="col-xsmall-12 col-small-6 col-large-4 special-diet-passenger-item-col">
                                          <div className="diet-passenger-item-inner">
                                            <h5 className="passenger-title">
                                              {Dictionary.passenger + " " + (+passengerCounterForSpecialFood+1)} <small className="no-wrap gray">
                                              ({item>1?Dictionary.child:Dictionary.infant} {(this.state.packageDetails.preReservedHotel.accommodation.rooms.length>1) && <> - {Dictionary.room + " " + (+roomIndex+1)}</>}
                                              )</small>
                                            </h5>
                                            {specialDietsList.map((dietItem,dietIndex)=><label key={dietItem.title} className='radio-btn-label type-2'>
                                              <input type="radio" 
                                                name={`passengers[${passengerCounterForSpecialFood}].mealType`}
                                                value={dietItem.value}
                                                disabled={item>1?false:true}
                                              />
                                              <span className="radio-btn-label-text">{dietItem.title}</span>
                                            </label>)}
                                          </div>
                                          {/* <div className="checkbox-holder">
                                            <input
                                              className="custom-checkbox"
                                              type="checkbox"
                                              name={`allExtraBaggageChecked`}
                                              checked ={passengerItem.services.extraBaggage}
                                              onChange={(e)=>this.changeExtraBaggage(e,passengerIndex)}
                                            />
                                            <label className="checkbox-label font-14">


                                              
                                              <span dir="ltr" className="margin-start-large">
                                                +<Price currency={this.props.currencyCode}>
                                                  {flightSelected.pricedItinerary.airItineraryPricingInfo.ptC_FareBreakdown.filter(typeItem => typeItem.passengerTypeQuantity.code === passengerItem.type)[0].passengerFare.extraBaggage}
                                                </Price>
                                              </span>
                                              
                                            </label>
                                          </div> */}
                                        </div>)
                                      })}
                                    </>)
                                  })
                                }
                              </div>
                              {/* <div className="float-row">
                                {this.state.passengerInformation.length &&
                                    this.state.passengerInformation.map((passengerItem,passengerIndex)=>(passengerItem.type !== "INF") ? 
                                :null)
                                }
                              </div> */}

                            </div>
                            }                          

                          <div className="card bg-white responsive-bg-transparent card-padding box-border blue-border-right">
                            <div className="float-row">
                              <div className="col-small-12 col-large-9">
                                <div className="form-group">
                                  <h5 className="normal-title">
                                    {Dictionary.description}
                                  </h5>
                                  <p>
                                    {Dictionary.EnterYourRequestsHereAndWeWillShareThemWithTheHotel}.
                                    {Dictionary.SpecialRequestsAreNotGuaranteedButTheHotelWillDoEverythingToMeetYourNeeds}.
                                  </p>

                                  <div className="form-group">
                                    <div className="select-input-pair-holder">
                                      <textarea
                                        className="form-input"
                                        name="specialRequest"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {this.state.packageDetails.preReservedHotel && this.state.packageDetails.preReservedHotel.accommodation.rooms[0].remarks && (
                            <div className="card box-border bg-white responsive-bg-transparent card-padding margin-top">
                              <div className=" margin-bottom">
                                <h5 className="normal-title">
                                  {Dictionary.remarks}:
                                </h5>
                                <div className="justified en-font" dir="ltr" dangerouslySetInnerHTML={{__html:this.state.packageDetails.preReservedHotel.accommodation.rooms[0].remarks}}/>
                              </div>
                            </div>
                          )}                         

                        </div>
                      </div>
                    </div>

                    <div className="small-side">
                      <div className="side-item full-width">
                        <div className="side-item-inner">
                          <PackageSummary
                            currencyCode={this.props.currencyCode}
                            showButton={true}

                            feild="passengerFare"
                            flightModel={this.state.packageDetails.preReservedFlight.pricedItinerary.airItinerary.originDestinationOptions}
                            hotelModel={this.state.packageDetails.preReservedHotel}
                            night={this.state.packageDetails.preReservedHotel.date.duration}
                            cancellationPolicy={this.state.packageDetails.preReservedHotel.cancellationPolicy}

                            flightPrice={this.state.packageDetails.preReservedFlight.pricedItinerary.airItineraryPricingInfo.itinTotalFare.totalFare}
                          />
                          <div className="box-border top-color-border orange margin-top bg-white">
                            <div className="colored">
                              {Dictionary.pricesToRiseSoon}!
                            </div>
                            <div className="font-12">
                              {" "}
                              {
                                Dictionary.bookNowToGetTheBestRateForYourCheckinDate
                              }
                              .{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>                      
                  </div>
                </div>
              </div>
            </form>     

          </div>
        </div>
      </div>
    )
  }
}
const dispatchMapProp = (dispatch) => ({
  editMessage: (param) => {
    dispatch(editMessage(param));
  },
  saveParameters: (param) => {
    dispatch(saveParameters(param));
  },
  switchLogin: (mode) => {
    dispatch(SwitchAccountWindow(mode));
  },
  check: () => dispatch(toggleAccountWindow()),
  postReserve: (params) => dispatch(postReserve(params)),
});
const stateMapProp = (state) => ({
  respond: state.VisualData.Responded,
  hotelInfo: state.HotelSearch.selectedHotel,
  preReserveKey: state.HotelSearch.parameters.preReserveKey,
  userData: state.UserData.userData,
  reserveId: state.HotelSearch.reserveId,
  currencyCode: state.UserData.Currency,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});

export default connect(stateMapProp, dispatchMapProp)(GetPassengerPackage);